import request from '@/utils/request'

// 查询客户划拨账单
export function findAgentAllocationOrder(id) {
  return request({
    url: `/customer/agent_allocation_orders/${id}`,
    method: 'get'
  })
}

// 查询客户划拨账单卡号列表
export function findSimCardAllocationOrders(id, params) {
  return request({
    url: `/customer/agent_allocation_orders/${id}/sim_card_allocation_orders`,
    method: 'get',
    params: params
  })
}

// 导出客户划拨账单卡号列表
export function exportSimCardAllocationOrders(id, data) {
  return request({
    url: `/customer/agent_allocation_orders/${id}/sim_card_allocation_orders/export`,
    method: 'post',
    data
  })
}

